import Vue from "vue";
import VueRouter from "vue-router";
// import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/imprint",
    component: () =>
      import("@/views/Imprint.vue"),
  },
  {
    path: "/generellterms",
    component: () =>
      import("@/views/GenerellTerms.vue"),
  },
  {
    path: "/secondopinion",
    meta: {
      about: true
    },
    component: () =>
      import("@/views/secondopinion/SecondOpinionContainer.vue"),
  },
  {
    path: "/shortopinion",
    meta: {
      about: true
    },
    component: () =>
      import("@/views/shortopinion/ShortOpinionContainer.vue"),
  },
  {
    path: "/form/:month",
    meta: {
      about: false
    },
    component: () =>
      import("@/views/FormContainer.vue"),
  },
  ...Array.from([1,3,6,12]).map(i => {
    return {
      path: "/nachbetreuung" + i + "monat",
      redirect: '/form/' + i,
      meta: {
        about: false
      },
      component: () => import("@/views/FormContainer.vue"),
    }
  }),
  {
    path: "/datenschutz",
    redirect: { path: "/generellterms", hash: "#privacypolicy"}
  },
  {
    path: "/datenschutz/",
    redirect: { path: "/generellterms", hash: "#privacypolicy"}
  },
  {
    path: "*",
    meta: {
      about: true
    },
    component: () =>
      import("@/views/Home.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
