<template>
  <div>
    <v-app-bar
      prominent
      app
      clipped-left
      color="primary"
      dark
      :height="$vuetify.breakpoint.xsOnly ? '90px' : ''"
      ref="AppBar"
    >
      <v-row
        class="mt-2"
        no-gutters
        style="max-width: 100%;"
        :justify="$vuetify.breakpoint.lg ? 'center' : 'space-between'"
      >
        <v-col cols="auto" class="mt-n2">
          <v-btn
            light
            tile
            elevation="0"
            :large="$vuetify.breakpoint.mdOnly"
            :class="$vuetify.breakpoint.smAndUp ? 'ml-n4 mt-n1 mr-1' : 'mr-0 ml-n4 mt-n1'"
            :style="$vuetify.breakpoint.smAndUp ? 'height: 128px' : 'height: 90px'"
            v-if="$vuetify.breakpoint.mdAndDown"
            @click="$store.commit('setNavigation', !$store.state.navigation)"
          >
            <v-icon large>mdi-menu</v-icon>
          </v-btn>
        </v-col>
        <v-spacer v-if="$vuetify.breakpoint.lgAndUp" />
        <v-col
          cols="6"
          sm="4"
          md="3"
          lg="2"
          :class="$vuetify.breakpoint.lgAndUp ? 'mt-2 mr-12' : 'mt-1'"
        >
          <v-img
            :src="icon"
            class="icon"
            :style="$vuetify.breakpoint.xsOnly ? 'max-height: 60px' : ''"
            contain
            @click="onLogoClick"
          />
        </v-col>
        <v-col cols="auto" v-if="$vuetify.breakpoint.smAndUp">
          <AppBarContact />
        </v-col>
        <v-spacer v-if="$vuetify.breakpoint.lgAndUp" />
        <v-col cols="auto" v-if="$vuetify.breakpoint.lgAndUp">
          <v-btn
            text
            :small="$vuetify.breakpoint.mdOnly"
            :x-large="$vuetify.breakpoint.xlOnly"
            @click="onLogoClick"
          >
            {{ $vuetify.lang.t('$vuetify.appbar.home') }}
          </v-btn>
        </v-col>
        <v-col cols="auto" v-if="$vuetify.breakpoint.lgAndUp">
          <v-btn
            text
            :small="$vuetify.breakpoint.mdOnly"
            :x-large="$vuetify.breakpoint.xlOnly"
            @click="onAbout"
          >
            {{ $vuetify.lang.t('$vuetify.appbar.about') }}
          </v-btn>
        </v-col>
        <v-col cols="auto" v-if="$vuetify.breakpoint.lgAndUp">
          <v-btn
            text
            :small="$vuetify.breakpoint.mdOnly"
            :x-large="$vuetify.breakpoint.xlOnly"
            @click="$router.push($route.path + '#contact')"
          >
            {{ $vuetify.lang.t('$vuetify.appbar.contact') }}
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <v-select
            class="mt-1"
            v-model="lang"
            :items="langs"
            dense
            solo
            light
            style="max-width: 75px"
            menu-props="overflowX"
          >
             <template v-slot:selection="{ item }">
                <img :src="item.img" />
             </template>
             <template v-slot:item="{ item }">
                <img class="mr-2" :src="item.img" /> {{ item.name }}
             </template>
          </v-select>
        </v-col>
      </v-row>

      <template v-slot:extension v-if="$route.path.includes('/secondopinion')">
        <v-tabs
          align-with-title
          color="white"
          v-if="$vuetify.breakpoint.lgAndUp"
        >
          <v-tab :to="$route.hash === '#more' ? '/secondopinion/' + $route.hash : '/secondopinion/#info'">
            {{ $vuetify.lang.t('$vuetify.appbar.secondopinionInfo') }}
          </v-tab>
          <v-tab to="#steps">
            {{ $vuetify.lang.t('$vuetify.appbar.secondopinionSteps') }}
          </v-tab>
          <v-tab :to="$route.hash === '#costcheck' ? '/secondopinion/#costcheck' : '/secondopinion/#costbearers'">
            {{ $vuetify.lang.t('$vuetify.appbar.secondopinionCostCheck') }}
          </v-tab>
          <v-tab to="/secondopinion/#experts">
            {{ $vuetify.lang.t('$vuetify.appbar.secondopinionExperts') }}
          </v-tab>
          <v-tab :to="$route.hash.includes('question') ? '/secondopinion/' + $route.hash : '/secondopinion/#faq'">
            {{ $vuetify.lang.t('$vuetify.appbar.secondopinionFaq') }}
          </v-tab>
          <v-tab to="/secondopinion/#documents">
            {{ $vuetify.lang.t('$vuetify.appbar.secondopinionDocuments') }}
          </v-tab>
        </v-tabs>
        <v-spacer />
        <Search class="mt-6" style="width: 350px" v-if="$vuetify.breakpoint.smAndUp" />
        <v-btn
          :x-large="$vuetify.breakpoint.smAndUp"
          class="mt-n6 mr-2"
          @click="toPatientPortal"
        >
          {{ $vuetify.lang.t('$vuetify.home.register') }}
        </v-btn>
        <!-- <v-btn
          color="default darken-1"
          :x-large="$vuetify.breakpoint.smAndUp"
          class="mt-n6"
          @click="toPatientPortal"
        >
          {{ $vuetify.lang.t('$vuetify.home.login') }}
        </v-btn> -->
      </template>

      <template v-slot:extension v-else-if="$route.path.includes('/shortopinion') && $vuetify.breakpoint.lgAndUp">
        <v-tabs
          align-with-title
          color="white"
          v-if="$vuetify.breakpoint.lgAndUp"
        >
          <v-tab to="/shortopinion/#info">
            {{ $vuetify.lang.t('$vuetify.appbar.shortopinionInfo') }}
          </v-tab>
          <v-tab to="/shortopinion/#benefits">
            {{ $vuetify.lang.t('$vuetify.appbar.shortopinionBenefits') }}
          </v-tab>
          <v-tab to="/shortopinion/#customers">
            {{ $vuetify.lang.t('$vuetify.appbar.shortopinionCustomers') }}
          </v-tab>
          <v-tab to="/shortopinion/#experts">
            {{ $vuetify.lang.t('$vuetify.appbar.secondopinionExperts') }}
          </v-tab>										
        </v-tabs>
        <Search class="mt-6" style="width: 350px" v-if="$vuetify.breakpoint.smAndUp" />
        <v-btn
          x-large
          class="mt-n6"
          @click="toBackofficePortal"
        >
          {{ $vuetify.lang.t('$vuetify.home.login') }}
        </v-btn>
      </template>

      <template v-slot:extension v-else-if="$vuetify.breakpoint.mdAndUp">
        <v-spacer />
        <Search class="mt-6" style="width: 500px"/>
      </template>
    </v-app-bar>
    <Navigation v-if="this.$vuetify.breakpoint.mdAndDown" />
  </div>
</template>

<script>
import Search from "@/views/appbar/Search"
import AppBarContact from "@/views/appbar/AppBarContact"
import Navigation from "@/views/appbar/Navigation"

export default {
  components: {
    Search,
    AppBarContact,
    Navigation
  },
  data(){
    return {
      langs: [
        {
          name: "Deutsch",
          img: require('@/assets/de.png'),
          value: "de"
        },
        {
          name: "English",
          img: require('@/assets/gb.png'),
          value: "en"
        }
      ],
      icon: require('@/assets/mdx_icon_white.png')
    }
  },
  computed: {
    lang: {
      get(){
        return this.$vuetify.lang.current
      },
      set(value){
        if(this.$store.state.cookieAccept === "yes"){
          localStorage.setItem("lang", value)
        }
        this.$vuetify.lang.current = value
      },
    },
  },
  methods: {
    onAbout(){
      if(this.$route.meta.about){
        this.$router.push(this.$route.path + '#about')
      } else {
        this.$router.push('/#about')
      }
    },
    toBackofficePortal(){
      window.open('https://portal.promedicly.com', '_blank')
    },
    toPatientPortal(){
      window.open('https://portal.medexo.com', '_blank')
    },
    onLogoClick(){
      if(this.$route.path !== "/"){
        this.$router.push("/")
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }
  }
}
</script>

<style lang="scss">
.icon {
  max-width: 320px !important;
  cursor: pointer;
}

.v-toolbar__extension {
  background-color: var(--v-secondary-base);
}
</style>
