import de from "vuetify/lib/locale/de";

export default {
  ...de,
  time: "Montag bis Freitag | 09:00 - 18:00 Uhr",
  telephone: "+49 30 555 7053 0",
  fax: "+49 30 555 7053 99",
  address: "Danziger Straße 104 | 10405 Berlin",
  addressTwoLine: "Danziger Straße 104 \n 10405 Berlin",
  email: "service@medexo.com",
  next: "Weiter",
  prev: "Zurück",
  more: "Weitere Informationen",
  motd: {
    title: "",
    text: "Sehr geehrte Nutzer, bitte beachten Sie, dass der 08.03.2023 in Berlin ein Feiertag ist. Wir sind ab dem 09.03.2023 wieder für Sie erreichbar."
  },
  map: {
    text: "Um eine Karte unseres Standorts anzuzeigen müssen Sie Cookies akzeptieren.",
    button: "Akzeptieren",
  },
  cookie: {
    title: "Diese Webseite verwendet Cookies",
    text: "Wir verwenden Cookies, um Inhalte und Anzeigen zu personalisieren.\nUnter anderem Präferenz-Cookies, welche es ermöglichen einer Webseite sich an Informationen zu erinnern, die die Art beeinflussen, wie sich eine Webseite verhält oder aussieht, wie z. B. Ihre bevorzugte Sprache.",
    yes: "Akzeptieren",
    essential: "Nur Essentiell",
    no: "Ablehnen",
  },
  appbar: {
    about: "Über Uns",
    contact: "Kontakt",

    home: "Startseite",

    secondopinionInfo: "Ihre Zweitmeinung",
    secondopinionSteps: "So gehts",
    secondopinionCostCheck: "Kostenübernahme",
    secondopinionExperts: "Ihre Spezialisten",
    secondopinionFaq: "Häufig gestellte Fragen",
    secondopinionDocuments: "Dokumente & Formulare",

    shortopinionInfo: "Unsere Plattform",
    shortopinionBenefits: "Warum Promedicly?",
    shortopinionCustomers: "Kunden & Partner",
  },
  bottomsheet: {
    imprint: "Impressum",
    generellTerms: "AGB",
    privacyPolicy: "Datenschutzerklärung"
  },
  home: {
    secondopinion: "Zweitmeinung für Patienten",
    secondopinionDetail: "Beurteilung einer Erstdiagnose durch einen unabhängigen Arzt.",
    shortopinion: "Medizinische Beurteilung &\n Gebührenprüfung",
    shortopinionDetail: "Unterschiedliche Leistungsfragen auf einer Plattform beauftragen.\nEinfach und zeitsparend.",
    login: "Login",
    register: "Direkt zum Zweitmeinungsportal",
    registerShort: "Direkt zur Zweitmeinung",
    more: "Weitere Informationen",
  },
  about: {
    title: "Über uns",
    title2: '"Medexo" steht für Medizinische Experten Online',
    text: "Wir sind ein aufstrebendes Unternehmen mit Sitz im Herzen Berlins und haben es uns zur Mission gemacht, die Patientenversorgung in Deutschland nachhaltig zu verbessern. Seit 2012 sind wir Vorreiter in der digitalen Leistungsprüfung für Versicherungen, indem wir Zweitmeinungen und medizinische Beurteilungen zwischen Versicherten verschiedener Krankenkassen und Expert:innen vermitteln. Dabei unterstützen wir nicht nur private Krankenversicherungen, sondern auch Gerichte und andere Institutionen.\n\nUnsere oberste Priorität liegt dabei auf dem Wohl des Versicherten, sowohl als Kunde des Versicherungssystems als auch als Patient innerhalb eines leitlinienorientierten und evidenzbasierten Gesundheitssystems. Unser Ziel ist es, Menschen den Zugang zu medizinisch notwendigen Behandlungen zu erleichtern und unnötige medizinische Eingriffe zu verhindern.\n\nUnsere Arbeitsweise ist dabei transparent und unabhängig, und ebenso unabhängig sind auch unsere Expert:innen. Wir setzen auf akribische Detailarbeit bei der Erstellung von Beurteilungen und bei der Gestaltung unserer speziell entwickelten Software-Plattform, die die digitale Zusammenarbeit zwischen den verschiedenen Beteiligten ermöglicht. Dabei legen wir besonderen Wert auf die Einhaltung der Datenschutz-Grundverordnung (DS-GVO) von Anfang an (Privacy by Design).\n\nWir betrachten uns als Partner unserer Kunden und schätzen den regen Austausch, um gemeinsam die Weiterentwicklung unserer Plattform voranzutreiben und so eine einfache, sichere Austauschplattform für alle Teilnehmer im Gesundheitsmarkt zu schaffen.",
    partner: {
      profession: "Geschäftsführender Gesellschafter"
    },
    experience: {
      "1": {
        "1": "Mehr als",
        "2": "20.000 Gutachten"
      },
      "2": {
        "1": "Seit 2016",
        "2": "Eigene entwickelte\nSoftware-Plattform",
      },
      "3": {
        "1": "Seit 2012",
        "2": "Erfahrung in telemedizinischen Prozessen"
      },
      "4": {
        "1": "Umfangreiches",
        "2": "Expertennetzwerk"
      }
    },
    team: {
      profession: {
        "1": "IT-Lead",
        "2": "Software Entwickler",
        "3": "Werkstudentin",
        "4": "Werkstudent",
        "5": "Ärztin",
        "6": "Head of Operations",
        "7": "Case Manager",
      },
    }
  },
  contact: {
    title: "So erreichen Sie uns",
    time: "Wir sind Montag bis Freitag von 9:00 bis 18:00 Uhr für Sie erreichbar."
  },
  experts: {
    title: "Ihre Spezialisten",
    subtitle1: "Medexo arbeitet ausschließlich mit anerkannten und unabhängigen Experten zusammen.\n Diese verfügen ausnahmslos über eine jahrelange Erfahrung und eine hohe Anerkennung und Akzeptanz in ihren Fachkreisen.",
    subtitle2: "Wir überprüfen unsere Expertennetzwerk regelmäßig anhand von verschiedenen Parametern in unterschiedlichen Kategorien,\num unsere Qualität unseres Netzwerkes sicherzustellen.",
    education: "Ausbildung",
    education0: "Spezialisierung",
    education1: "Habilitation",
    education2: "(ehemalige) Leitungsposition",
    education3: "Weiterbildungserlaubnis",
    societies: "Wissenschaft und Auszeichnungen",
    societies0: "Experten-Liste",
    societies1: "Publikationen",
    societies2: "Kongresspräsidium",
    societies3: "Forschungspreise",
    reviewer: "Gutachter und Leitlinienmitgliedschaft",
    reviewer0: "Gerichtsgutachter",
    reviewer1: "Über 5 Jahre Erfahrung als Gutachter",
    reviewer2: "Mitglied AWMF",
  },
  shortopinion: {
    info: {
        text1: "Software-Plattform für Versicherungen",
        text2: "Zur Beauftragung von medizinischen Beurteilungen, Leistungsfragen und Rechnungsprüfung. \n Erhalten Sie kurzfristig eine Beurteilung von unseren erfahrenen Spezialisten.",
        card1: {
          title: "Ihre Bedürfnisse",
          text1: "Effizienzsteigerung durch passgenaues Leistungsmanagement",
          text2: "Schnelle Beurteilung unter Einhaltung des Datenschutzes",
          text3: "Einfache Prozesse",
          text4: "Effiziente Kommunikation",
          text5: "Belastbare medizinische Beurteilung von komplexen Themen",
          text6: "Ein Ansprechpartner für alle Leistungsfälle",
        },
        card2: {
          title: "Unser Service",
          text1: "Alle Fragen",
          text2: "Alle Unterlagen",
          text3: "Alle Fachbereiche",
          text4: "Alle Beurteilungen",
          text5: "Alle Abrechnungsarten",
          additional: "Alles aus einer Hand"
        }
    },
    benefits: {
      title: "Warum Promedicly?",
      title1: "1. Einfache Nutzung über Internetbrowser ohne Installation",
      text1: "Schneller Projektstart;Unabhängig von eigener IT-Infrastruktur;Ideal für HomeOffice und hybrides Arbeiten",
      title2: "2. Kinderleicht zu bedienende Oberfläche",
      text2: "Schnelle Erfolge der Mitarbeiter auch ohne tiefgreifende Computer-Erfahrung;Nur wenige Klicks bis zum Absenden des Auftrags;Der Status des Falls kann jederzeit eingesehen werden, dadurch maximale Transparenz in der Kommunikation mit Versicherungsnehmern",
      title3: "3. Datenschutz",
      text3: "Login mit 2-Faktor-Authentifizierung;Datenschutz konform;ISMS integriert;Externer Datenschützer;Software mehrfach durch Penetrationstests geprüft",
      title4: "4. Sie haben die Kontrolle",
      text4: "Sie möchten individuelle Einstellung? - Gerne!;Sie möchten die Unterlagen automatisch geschwärzt haben? - Gerne!;Wir sollen das Schwärzen übernehmen? - Gerne! (mit Auftragsverarbeitungsvertrag)",
    },
    customers: {
      title: "Kunden & Partner",
      title1: "Kunden",
      title2: "Partner"
    }
  },
  secondopinion: {
    info: {
      title: "Was ist eine Zweitmeinung?",
      text: "Eine Zweitmeinung ist die Beurteilung einer Erstdiagnose durch einen unabhängigen Arzt. Darin enthalten ist eine Einschätzung, ob eine Operation im vorliegenden Fall angebracht oder eine alternative Behandlung besser geeignet ist. Über Medexo können Patienten eine Zweitmeinung bequem über das Internet beantragen und erhalten. Wir geben den einkommenden Fall an einen darauf spezialisierten Experten unseres Netzwerks weiter und übertragen das von ihm verfasste Gutachten in eine Laien-freundliche Sprache.\n\nZweitmeinungen sind immer dann angebracht, wenn ein Patient Bedenken hat, ob die von seinem Arzt empfohlene Behandlung wirklich notwendig und empfehlenswert ist. Besonders vor schwerwiegenden Entscheidungen, wie der Wahl einer Chemotherapie, ist das Einholen einer zweiten Meinung angebracht.",
      title2: "Unser Service",
      text2: "Speziell auf Ihr Problem abgestimmter Fragebogen;Objektive Meinung eines Experten, ob eine Operation in Ihrem Falle angebracht ist;Laienverständliche Beurteilung der Erstdiagnose und Behandlungsempfehlung;Bereits viele Krankenversicherer vertrauen dem Service von Medexo",
      card1: {
        title: "Was ist eine Zweitmeinung?",
        text: "Überprüfung der Diagnose und Therapieempfehlung;Handlungsempfehlung;Extra leicht verständlich",
        more: "Weitere Informationen",
      },
      card2: {
        title: "Wer erstellt die Zweitmeinung?",
        text: "Renommierte Spezialisten aus Deutschland;Experten mit großer Erfahrung im betreffenden Fachgebie;Unabhängig durch Weiterbehandlungsverbot",
        more: "Ihre Spezialisten",
      }
    },
    steps: {
      title: "So geht's: In 3 Schritten zur Zweitmeinung",
      step1_title: "1. IST-Zustand erfassen",
      step1_text: "Der erste Schritt besteht aus dem Ausfüllen unseres Fragebogens und der Zusammenstellung Ihrer medizinischen Unterlagen. \n Dies ist notwendig, damit unsere Experten ihre Zweitmeinung auf Basis vollständiger medizinischer Informationen erstellen können.",
      step2_title: "2. Unterlagen übermitteln",
      step2_text: "Nachdem Sie uns Ihre Daten digital oder postalisch übermittelt haben, geben wir diese an den für das vorliegende Fachgebiet zuständigen Experten weiter. Nach gründlicher Untersuchung Ihres Falls erstellt dieser eine umfangreiche Zweitmeinung.",
      step3_title: "3. Zweitmeinung erhalten",
      step3_text: "Nachdem die Bearbeitung durch den Experten abgeschlossen ist, erhalten Sie Ihre Zweitmeinung innerhalb weniger Tage. Die Zweitmeinung können Sie sich dann bequem in Ihrem Benutzerkonto herunterladen. Alternativ schicken wir sie Ihnen auch postalisch zu."
    },
    costCheck: {
      title1: "Kostenübernahme",
      title2: "Viele Krankenkassen und Krankenversicherer übernehmen die Kosten",
      additional: "Einige Eingriffe sind davon ausgeschlossen. *",
      button: "Jetzt prüfen",
      costBearer: "Versicherung auswählen",
      costBearerFilled: "Versicherung: ",
      subject: "Fachbereich auswählen",
      subjectFilled: "Fachbereich: ",
      finish: "Ergebnis",
      true: "Die Kosten für eine Zweitmeinung werden von Ihrem Krankenversicherer übernommen!",
      register: "Jetzt anmelden",
      false: "Die Kosten für eine Zweitmeinung werden von Ihrem Krankenversicherer NICHT übernommen!",
      help: "Bitte wenden Sie sich an Ihre Krankenkasse."
    },
    faq: {
      title: "Häufig gestellte Fragen",
      question1: "Wie lange dauert die Erstellung einer Zweitmeinung?",
      answer1: "Nachdem alle medizinischen Unterlagen vollständig bei uns eingegangen sind, wird die Zweitmeinung innerhalb von maximal 10 Werktagen erstellt. In besonders dringenden Fällen kann eine Zweitmeinung auch in kürzerer Zeit erstellt werden, bitte kontaktieren Sie uns zur Prüfung telefonisch. Hierfür müssen wir allerdings eine zusätzliche Gebühr erheben, da unser Spezialist deutlich schneller an Ihrem Fall arbeiten muss.",
      question2: "Was ist, wenn ich nach Erhalt der Zweitmeinung noch Fragen habe?",
      answer2: "Wir legen großen Wert auf eine zufriedenstellende Kundenbetreuung. Dies bezieht sich auf den gesamten Zeitraum vor, während und nach Erhalt der medizinischen Zweitmeinung. Sollten also noch Fragen auftreten, nachdem Sie die Zweitmeinung bereits erhalten haben, stehen unser ärztlicher Kundenservice und unsere Experten selbstverständlich für eine nachträgliche Beantwortung zur Verfügung.",
      question3: "Sind Ferndiagnosen nicht illegal?",
      answer3: "Wir bieten keine Ferndiagnosen an. Die Zweitmeinungen unserer Experten sind eine hervorragende Ergänzung zum Arztbesuch vor Ort, ersetzen diesen jedoch nicht. Der Spezialist führt keine Ferndiagnose durch, sondern erstellt eine unabhängige Meinung zu Ihrer unmittelbaren Behandlung vor Ort auf Basis Ihrer medizinischen Daten. Unsere Arbeit ist daher absolut legal und leistet einen wichtigen Beitrag zur Sicherheit der Patienten. Eine unmittelbare Besprechung der Zweitmeinung bei einem Kollegen vor Ort und die unmittelbare Weiterbehandlung sind Voraussetzung für eine sinnvolle Umsetzung der Empfehlungen unserer Experten. Mit unserer einjährigen Nachbetreuung lassen wir Sie auch nach Zweitmeinung bei Fragen auch zur Weiterbehandlung nicht allein.",
      question4: "Sind Ihre Zweitmeinungen vor Gericht haltbar?",
      answer4: "Grundsätzlich sind unsere Zweitmeinungen für Sie als Patienten bestimmt und dienen als Hilfestellung bei Ihrer Entscheidungsfindung. Ein gerichtliches Gutachten ist mit deutlich mehr Aufwand verbunden und liegt nicht in unserem Fokus.",
      question5: "Was für Unterlagen brauchen Sie?",
      answer5: "Grundsätzlich brauchen wir einen Arztbrief Ihres letzten, Ihre aktuelle Anfrage betreffenden Arztbesuchs, einen von Ihnen ausgefüllten Fragebogen und eine entsprechende Bildgebung Ihres: Knies (Röntgen MRT), Rückens (MRT oder CT), Fußes (Röntgen), etc. Sollten noch Unterlagen fehlen, teilen wir Ihnen dies unverzüglich mit und unterstützen Sie auch gerne dabei, diese zu erhalten.",
      question6: "Wie bekomme ich meine Unterlagen vom Arzt?",
      answer6: "Jeder Arzt ist verpflichtet, seinem Patienten Einsicht in seine Krankenakte zu gewähren. Sie können also Kopien anfordern, möglicherweise fällt dafür ein Unkostenbeitrag an. Anmerkung: Unabhängig von dieser Zweitmeinung ist es wichtig, stets Ihre Unterlagen beim Arzt einzufordern (im Falle einer Praxisschließung, eines Umzuges etc.). Der Arzt ist verpflichtet, auf Antrag die Kopien oder Ausdrucke zu fertigen, herauszugeben und zu versichern, dass die herausgegebenen Unterlagen vollständig sind (§ 10 Abs. 2 S. 2 MBO-Ä).",
      question7: "Wie übermittle ich meine Patientenunterlagen?",
      answer7: "Nach der Registrierung und dem Ausfüllen des Fragebogens können Sie Ihre Unterlagen (Befunde, Röntgenbilder, CT-Scans) Ihren persönlichen Bereich hochladen. Sie können Ihre gesamten Unterlagen auch einfach postalisch an uns schicken und wir übernehmen die Weitergabe an den zuständigen Experten für Sie. Alle Bilder und Befunde, die Sie uns in postalischer Form zukommen lassen, werden nach Abschluss der Zweitmeinung wieder an Sie zurückgesendet.",
      question8: "Wie sicher sind meine Daten, wenn ich Ihnen meine Unterlagen hochlade/schicke?",
      answer8: "Der Schutz Ihrer Daten hat für uns höchste Priorität. Die Übertragung Ihrer Unterlagen ist durch das so genannte SSL Zertifikat verschlüsselt und abgesichert. Zusätzlich legen wir alle Daten verschlüsselt und anonymisiert bei uns ab. Sollten Sie uns Ihre Unterlagen per Post schicken gelten diese Sicherheitsvorkehrungen genauso und wir senden Ihre Originale an Sie zurück, sobald Sie Ihre Zweitmeinung erhalten haben.",
      question9: "Wer hat Zugriff auf meine Daten?",
      answer9: "Ausschließlich Sie haben Zugriff auf Ihren persönlichen, kennwortgeschützten Bereich. Bitte bewahren Sie deshalb Ihre Zugangsdaten sorgfältig auf und geben Sie sie nicht an Dritte weiter. Die Erhebung und Verarbeitung Ihrer personenbezogenen Daten erfolgt lediglich zur Beantwortung und Abwicklung Ihrer Zweitmeinungsanfrage. Wir behandeln Ihre Daten streng vertraulich und unter Beachtung sämtlicher gesetzlicher Bestimmungen. Eine Weitergabe Ihrer Daten an Dritte erfolgt, mit Ausnahme des jeweils für die Beantwortung und Abwicklung Ihrer Zweitmeinungsanfrage betrauten Experten, selbstverständlich nicht.",
      question10: "Kann ich einen bestimmten Spezialisten auswählen, der meine Zweitmeinung erstellt?",
      answer10: "Wir setzen uns detailliert mit Ihrer Zweitmeinungsanfrage auseinander und wählen den für Ihren Fall am besten geeigneten Spezialisten aus. Anfragen an bestimmte Spezialisten werden nach Möglichkeit berücksichtigt, können jedoch aufgrund von Verfügbarkeit nicht garantiert werden.",
      question11: "Warum sollte ich bei Ihnen eine Zweitmeinung einholen und nicht zu einem weiteren ambulanten Arzt gehen?",
      answer11: "Unsere Experten sind keine Generalisten, sondern ausgesuchte Spezialisten mit umfassendem Fachwissen und langjähriger Erfahrung auf ihrem Gebiet. Da der Experte Ihnen ausschließlich eine Zweitmeinung erstellen und Sie nicht weiter behandeln darf, entsteht für ihn kein Fehlanreiz, Ihnen eine Operation zu empfehlen. Unser Experte beschäftigt sich intensiv mit Ihrem Fall und Sie erhalten die Zweitmeinung innerhalb weniger Tage, was ein ambulanter Arzt aufgrund der Vielzahl seiner Patienten und langen Wartezeiten selten gewährleisten kann. Gerade die Unabhängigkeit und Neutralität unserer Experten ist einer der großen Vorteile unseres Angebots",
      question12: "Der Fachbereich oder das Spezialgebiet wird in der Auswahl nicht angezeigt. Woran liegt das?",
      answer12: "Einige Eingriffe können wir aus rechtlichen Gründen nicht abieten. Weitere Informationen unter:\n"
    },
    documents: {
      title: "Dokumente & Formulare",
						contract: "Zweitmeinungsvertrag",
						attorney: "Vollmacht für Unterlagenanforderung",
      declaration: "Teilnahmeerklärung"
    }
  },
  imprint: {
    title: "Impressum",
    company: "Medexo - Medizinische Experten Online ist ein Service der Medexo GmbH",
    partnerTitle: "Vertreten durch die Geschäftsführer",
    partnerText: "Dr. med Jan-Christoph Log | CEO\nTim Niemeyer | CFO",
    registerTitle: "Registereintrag",
    registerText: "Registergericht: AG Charlottenburg\nRegisternummer: HRB 142224 B",
    taxTitle: "Umsatzsteuer-Identifikationsnummer",
    taxText: "DE 283254108",
    responsibleTitle: "Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV",
    responsibleText: "Dr. med. Jan-Christoph Loh\nDanziger Straße 104\n10405 Berlin",
    dataProtectionTitle: "Datenschutzbeauftragter",
    dataProtectionText: "Medexo GmbH\nDatenschutzbeauftragter\nDanziger Straße 104\n10405 Berlin\ndatenschutz@medexo.com",
    disclaimerTitle: "Haftungsausschluss",
    disclaimerSubtitle1: "Haftung für Inhalte",
    disclaimerText1: "Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.",
    disclaimerSubtitle2: "Haftung für Links",
    disclaimerText2: "Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.",
    disclaimerSubtitle3: "Haftung für Urheberrecht",
    disclaimerText3: "Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.",
    grafikTitle: "Grafiken, Logos  & Bilder auf dieser Seite stammen von:",
    grafikText: "Karolina Grabowska - Pexels \n fauxels - Pexels \n Pixabay - Pexels"
  },
  generellTerms: {
    title: "AGB"
  },
}
