import Vue from "vue";

export default {
  addEntities(state, payload) {
    Vue.set(state, payload.entityName, payload.items);
  },
  setNavigation(state, value) {
    Vue.set(state, "navigation", value);
  },
  setCookieAccept(state, value) {
    switch (value) {
      case "yes":
        localStorage.setItem("cookieDialog", false)
        localStorage.setItem("cookieAccept", "yes")
        break;
      case "essential":
        localStorage.setItem("cookieDialog", false)
        localStorage.setItem("cookieAccept", "essential")
        break;
    }
    Vue.set(state, "cookieAccept", value);
    Vue.set(state, "cookieDialog", false);
  },
  setMotd(state, value) {
    Vue.set(state, "motd", value);
  },
};
