<template>
  <v-navigation-drawer
    v-model="navigation"
    app
  >
    <v-list
      nav
      dense
      shaped
      v-for="(item, i) in items"
      :key="i"
      :class="i === 0 ? 'mt-3' : ''"
    >
      <div>
        <v-list-item v-if="item.click" exact :to="item.to" @click="item.click">
          <v-list-item-title v-text="item.label" />
        </v-list-item>
        <v-list-item v-else exact :to="item.to">
          <v-list-item-title v-text="item.label" />
        </v-list-item>
        <v-divider v-if="item.divider"/>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  computed: {
    navigation: {
      get() {
        return this.$store.state.navigation;
      },
      set(value) {
        this.$store.commit("setNavigation", value);
      },
    },
    width() {
      switch (this.$vuetify.breakpoint.name) {
        // case "xl":
        //   return "16%";
        // case "lg":
        //   return "20%";
        default:
          return "";
      }
    },
    items(){
      return [
        {
          label: this.$vuetify.lang.t('$vuetify.appbar.home'),
          to: "/"
        },
        {
          label: this.$vuetify.lang.t('$vuetify.appbar.about'),
          to: this.$route.path + '#about',
          click: this.onAbout
        },
        {
          label: this.$vuetify.lang.t('$vuetify.appbar.contact'),
          to: this.$route.path + '#contact',
          divider: true
        },
        {
          label: this.$vuetify.lang.t('$vuetify.appbar.secondopinionInfo'),
          to: this.$route.hash === '#more' || this.$route.hash === '#steps' ? '/secondopinion/' + this.$route.hash : '/secondopinion/#info'
        },
        {
          label: this.$vuetify.lang.t('$vuetify.appbar.secondopinionCostCheck'),
          to: this.$route.hash === '#costcheck' ? '/secondopinion/#costcheck' : '/secondopinion/#costbearers'
        },
        {
          label: this.$vuetify.lang.t('$vuetify.appbar.secondopinionExperts'),
          to: "/secondopinion/#experts"
        },
        {
          label: this.$vuetify.lang.t('$vuetify.appbar.secondopinionFaq'),
          to: this.$route.hash.includes('question') ? '/secondopinion/' + this.$route.hash : '/secondopinion/#faq',
          divider: true
        },
        {
          label: this.$vuetify.lang.t('$vuetify.appbar.shortopinionInfo'),
          to: "/shortopinion/#info"
        },
        {
          label: this.$vuetify.lang.t('$vuetify.appbar.shortopinionBenefits'),
          to: "/shortopinion/#benefits"
        },
        {
          label: this.$vuetify.lang.t('$vuetify.appbar.shortopinionCustomers'),
          to: "/shortopinion/#customers",
          divider: true
        },
      ]
    }


  },
  methods: {
    onAbout(){
      if(this.$route.meta.about){
        this.$router.push(this.$route.path + '#about')
      } else {
        this.$router.push('/#about')
      }

    }
  }
};
</script>

<style lang="scss">
.v-list-item__action {
  margin: 0px 6px 0px 0px !important;
}
</style>
