<template>
  <v-container id="contact" class="anchor">
    <v-row class="mt-12">
      <v-col cols="12" sm="6">
        <p class="text-h2">
          {{$vuetify.lang.t('$vuetify.contact.title')}}
        </p>
        <p class="text-subtitle-1">
          {{$vuetify.lang.t('$vuetify.contact.time')}}
        </p>
        <p class="text-body-1">
          <v-icon>mdi-phone</v-icon>
          {{$vuetify.lang.t('$vuetify.telephone')}}
        </p>
        <p class="text-body-1">
          <v-icon>mdi-fax</v-icon>
          {{$vuetify.lang.t('$vuetify.fax')}}
        </p>
        <p class="tex-body-1">
          <v-icon>mdi-map-marker</v-icon>
          {{$vuetify.lang.t('$vuetify.address')}}
        </p>
        <p class="text-body-1">
          <v-icon>mdi-email</v-icon>
          {{$vuetify.lang.t('$vuetify.email')}}
        </p>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card class="map" v-if="cookieAccept">
          <l-map style="height: 500px" :zoom="zoom" :center="center" :options="{scrollWheelZoom:false}">
            <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
            <l-marker :lat-lng="markerLatLng"></l-marker>
          </l-map>
        </v-card>
        <div v-else>
          <p>
            {{$vuetify.lang.t('$vuetify.map.text')}}
          </p>
          <v-btn
            color="default" class="pa-auto"
            @click="onClick"
          >
            {{$vuetify.lang.t('$vuetify.map.button')}}
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  data () {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 15,
      center: [52.53692, 13.43002],
      markerLatLng: [52.53692, 13.43002]
    };
  },
  computed: {
    cookieAccept(){
      return this.$store.state.cookieAccept === "yes"
    }
  },
  methods: {
    onClick(){
      this.$store.commit("setCookieAccept", "yes")
    }
  }
}
</script>

<style lang="scss">
.map {
  z-index: 2
}
</style>
