<template>
  <v-dialog
    v-model="show"
    persistent
    :width="$vuetify.breakpoint.smAndDown ? '100%' : '50%'"
  >
    <v-card>
      <v-card-title>
        {{$vuetify.lang.t('$vuetify.cookie.title')}}
      </v-card-title>
      <v-card-text style="white-space: pre-line">
        {{$vuetify.lang.t('$vuetify.cookie.text')}}
      </v-card-text>
      <v-card-actions>
        <v-row no-gutters justify="center">
          <v-col cols="12" sm="auto">
            <v-btn
              color="primary"
              @click="() => onClick('yes')"
              :block="$vuetify.breakpoint.xsOnly"
            >
              {{$vuetify.lang.t('$vuetify.cookie.yes')}}
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            sm="auto"
            :class="$vuetify.breakpoint.xsOnly ? 'py-2' : 'px-2'"
          >
            <v-btn
              color="primary"
              @click="() => onClick('essential')"
              :block="$vuetify.breakpoint.xsOnly"
            >
              {{$vuetify.lang.t('$vuetify.cookie.essential')}}
            </v-btn>
          </v-col>
          <v-col cols="12" sm="auto">
            <v-btn
              color="primary"
              @click="() => onClick('no')"
              :block="$vuetify.breakpoint.xsOnly"
            >
              {{$vuetify.lang.t('$vuetify.cookie.no')}}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  computed: {
    show: {
      get(){
        return this.$store.state.cookieDialog
      },
      set(){}
    }
  },
  methods: {
    onClick(value){
      this.$store.commit("setCookieAccept", value)
    }
  }
}
</script>
