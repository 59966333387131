<template>
  <div class="mt-2">
    <v-menu
      v-model="show"
      offset-y
      max-height="500"
      max-width="500"
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          class="mr-4"
          solo
          light
          placeholder="Suche"
          append-icon="mdi-magnify"
          v-bind="attrs"
          v-on="on"
          v-model="input"
          dense
        />
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          @click="$router.push(item.to)"
        >
          <v-list-item-content >
            <v-list-item-title v-if="item.title">
              {{ item.title }}
            </v-list-item-title>
            <v-list-item-subtitle v-if="item.subtitle">
              {{ item.subtitle }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  data(){
    return {
      input: ""
    }
  },
  computed: {
    items(){
      let current = this.$vuetify.lang.current
      let locale = this.$vuetify.lang.locales[current]
      let search = this.search(locale)
      search = this.input !== "" ? search.filter(e => e.value.toLowerCase().includes(this.input.toLowerCase())) : []
      let result = []
      search.forEach(x => {
        let num = x.key.includes("question") ? x.key.split("question")[1] : x.key.split("answer")[1]

        if(x.key.includes("about") && !result.find(y => y.to === this.$route.path + "#about")){
          result.push({
            ...x,
            title: this.$vuetify.lang.t('$vuetify.appbar.about'),
            subtitle: x.value,
            to: this.$route.path + "#about"
          })
        } else if(x.key.includes("secondopinion.info") && !result.find(y => y.to === "/secondopinion/#info")){
          result.push({
            ...x,
            title: this.$vuetify.lang.t('$vuetify.secondopinion.info.title'),
            subtitle: x.value,
            to: "/secondopinion/#more"
          })
        } else if(x.key.includes("secondopinion.steps") && !result.find(y => y.to === "/secondopinion/#steps")){
          result.push({
            ...x,
            title: this.$vuetify.lang.t('$vuetify.secondopinion.steps.title'),
            subtitle: x.value,
            to: "/secondopinion/#steps"
          })
        } else if(x.key.includes("secondopinion.costCheck") && !result.find(y => y.to === "/secondopinion/#costcheck")){
          result.push({
            ...x,
            title: this.$vuetify.lang.t('$vuetify.secondopinion.costCheck.title1'),
            subtitle: x.value,
            to: "/secondopinion/#costcheck"
          })
        } else if(x.key.includes("secondopinion.experts") && !result.find(y => y.to === "/secondopinion/#experts")){
          result.push({
            ...x,
            title: this.$vuetify.lang.t('$vuetify.secondopinion.experts.title'),
            subtitle: x.value,
            to: "/secondopinion/#experts"
          })
        } else if(!x.key.includes("title") && x.key.includes("secondopinion.faq") && !result.find(y => y.to === "/secondopinion/#question" + num)){
          result.push({
            ...x,
            title: this.$vuetify.lang.t('$vuetify.secondopinion.faq.question' + num),
            to: "/secondopinion/#question" + num
          })
        }
      })
      return result
    },
    show: {
      get() {
        return this.input.length > 0
      },
      set() {}
    }
  },
  methods: {
    search(object, beforeKey){
      let result = []
      Object.keys(object).forEach(key => {
        if(typeof object[key] === "object"){
          result.push(...this.search(object[key], beforeKey ? beforeKey + "." + key : key))
        } else if(
          key.includes("text") ||
          key.includes("title") ||
          key.includes("answer") ||
          key.includes("question")
        ){
          result.push({
            key: beforeKey + "." + key,
            value: object[key]
          })
        }
      });
      return result
    }
  }
}
</script>
