<template>
  <div>
    <v-avatar
      :size="$vuetify.breakpoint.xsOnly ? 80 : 120"
    >
      <v-img v-if="img" :src="img" />
      <v-icon
        :size="$vuetify.breakpoint.xsOnly ? 80 : 120"
        v-else
      >
        mdi-account
      </v-icon>
    </v-avatar>
    <p class="text-subtitle-1 mb-0">
      {{name}}
    </p>
    <p class="text-subtitle-2 mb-0" v-if="profession">
      {{$vuetify.lang.t('$vuetify.about.team.profession.' + profession)}}
    </p>
  </div>
</template>

<script>
export default {
  props: ["name","img","profession"],
}
</script>
