import Vue from "vue";
import Vuex from "vuex";
import { createLogger } from "vuex";

import modules from "./modules";
import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    navigation: false,
    cookieDialog: localStorage.getItem("cookieDialog") === "false" ? false : true,
    cookieAccept: localStorage.getItem("cookieAccept") || "no"
  },
  mutations,
  actions,
  getters,
  modules,
  plugins: [
    createLogger({
      collapsed: false,
      // logMutations: false,
    }),
  ],
});
