<template>
  <div>
    <v-card color="card darken-1" class="d-flex" shaped>
      <div class="my-auto pa-4">
        <v-avatar
          :size="$vuetify.breakpoint.xsOnly ? 100 : 140"
        >
          <v-img :src="img"></v-img>
        </v-avatar>
      </div>
      <v-card-text class="ma-auto">
        <div>
          <p class="text-h5 mb-0">
            {{name}}
          </p>
          <p class="text-h6 mb-0">
            {{profession}}
          </p>
          <p class="text-subtitle-1 mb-0">
            <v-icon>
              mdi-email
            </v-icon>
            {{email}}
          </p>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["name","img","profession","email"],
}
</script>
