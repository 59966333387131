<template>
  <v-container class="mt-4">
    <p id="about" class="anchor text-h2">
      {{$vuetify.lang.t('$vuetify.about.title')}}
    </p>
    <v-row>
      <v-col cols="12" lg="6">
        <p class="text-h5 primary--text">
          {{ $vuetify.lang.t('$vuetify.about.title2') }}
        </p>
        <p class="text-body-1" style="white-space: pre-line">
          {{ $vuetify.lang.t('$vuetify.about.text') }}
        </p>
      </v-col>
      <v-col cols="12" lg="6">
        <Team />
      </v-col>
      <v-col cols="12" lg="6">
        <Partner
          name="Tim Niemeyer"
          :profession="$vuetify.lang.t('$vuetify.about.partner.profession')"
          email="t.niemeyer@medexo.com"
          :img="niemeyer"
        />
      </v-col>
      <v-col cols="12" lg="6">
        <Partner
          name="Dr. Jan-Christoph Loh"
          :profession="$vuetify.lang.t('$vuetify.about.partner.profession')"
          email="jan.loh@medexo.com"
          :img="loh"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Team from "@/views/about/Team"
import Partner from "@/views/about/Partner"

export default {
  components: {
    Team,
    Partner
  },
  data(){
    try {
      return {
        loh: require('@/assets/team/Loh.jpg'),
        niemeyer: require('@/assets/team/Niemeyer.jpg')
      }
    } catch (e) {
      return {
        loh: null,
        niemeyer: null
      }
    }
  }
}
</script>
