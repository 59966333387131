export default {
  READ_Entities(store, entityName) {
    let headers = {
      APIKEY: process.env.VUE_APP_APIKEY
    };

    let url = process.env.VUE_APP_APIURL + "/v1/websites/" + entityName.toLowerCase() + "?limit=0";


    return fetch(url, {
      method: "GET",
      headers
    }).then(async (response) => {
        switch (response.status) {
          case 200:
            return response.json()
          default:
            return response.json().then((error) => {
              throw error;
            });
        }
    }).then((response) => {
      store.commit("addEntities", {
        entityName,
        items: response.items,
      });
    }).catch((error) => {
      throw error;
    });
  },
  READ_Motd(state) {
    let url = process.env.VUE_APP_APIURL + "/v1/motds/current?siteType=website"

    return fetch(url, {
      method: "GET",
    }).then(async (response) => {
        if(response.status === 200){
          let value = await response.json()
          state.commit("setMotd", value)
        }
    })
  },
};
