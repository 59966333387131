<template lang="html">
  <v-app>
    <AppBar />
    <CookieDialog />
    <v-main>
      <v-alert
        dense
        :type="motd.type"
        color="default darken-1"
        prominent
        class="motd"
        v-if="motd"
      >
        <p class="text-h5 ma-0 mt-2">
          {{ motd.name }}
        </p>
        <p class="text-subtitle-1">
          {{ motd.content }}
        </p>
      </v-alert>
      <router-view />
      <v-divider class="mt-8" />
      <About v-if="$route.meta.about" class="mt-16"/>
      <Contact />
    </v-main>
    <BottomSheet />
  </v-app>
</template>

<script>
import AppBar from "@/views/appbar/AppBar"
import BottomSheet from "@/views/BottomSheet"
import CookieDialog from "@/views/CookieDialog"

import About from "@/views/about/About"
import Contact from "@/views/Contact"

export default {
  components: {
    AppBar,
    BottomSheet,
    CookieDialog,
    About,
    Contact
  },
  data(){
    return {
      disableScrollBehavior: false,
      scrollTo: null,
      showAbout: false
    }
  },
  watch: {
    $route(route){
      this.$nextTick(() => {
        if (route.hash && !this.disableScrollBehavior) {
          let num = document.querySelector(route.hash).offsetTop - 10
          this.scrollTo = num > 0 ? num : 0
          window.scrollTo({
            top: this.scrollTo,
            behavior: 'smooth'
          })
        } else if(!route.hash && !this.disableScrollBehavior){
          this.scrollTo = 0
          window.scrollTo({
            top: this.scrollTo,
            behavior: 'smooth'
          })
        } else {
          this.disableScrollBehavior = false
        }
      })
    }
  },
  computed: {
    motd() {
      return this.$store.getters.getMotd
    }
  },
  created(){
    this.$vuetify.lang.defaultLocale = "de"
    window.addEventListener('scroll', this.onScroll)
    this.$store.dispatch("READ_Motd")
  },
  methods: {
    onScroll(){
      const scrollTop = Math.max(
        window.pageYOffset,
        document.documentElement.scrollTop,
        document.body.scrollTop
      )

      if(!this.scrollTo || scrollTop === this.scrollTo){
        this.scrollTo = null

        const anchors = document.getElementsByClassName("anchor");

        for (let i = 0; i < anchors.length; i++) {
          const anchor = anchors[i]
          const nextAnchor = anchors[i + 1]

          const isActive = i === 0 && scrollTop === 0
            || (scrollTop >= anchor.offsetTop - 10
              && (!nextAnchor || scrollTop < nextAnchor.offsetTop + 10))

          if (isActive && this.$route.hash !== "" && this.$route.hash !== "#" + anchor.id) {
            this.disableScrollBehavior = true
            this.$router.replace(this.$route.path + "#" + anchor.id)
          }
        }
      }
    },
  }
}
</script>

<style lang="scss">
.motd {
  border-radius: 0 !important;
  margin: 0 !important;
  white-space: pre-line;
}
</style>
