<template>
  <v-card
    dense
    color="default darken-1"
  >
    <v-row no-gutters :justify="$vuetify.breakpoint.mdAndUp ? 'center' : ''">
      <v-col cols="auto">
        <v-card-title :class="titleClassLeft">
          <v-icon class="mr-2">mdi-phone</v-icon>
          {{ $vuetify.lang.t('$vuetify.telephone') }}
          <p :class="timeClass + ' caption hidden-lg-and-down'">
            {{ $vuetify.lang.t('$vuetify.time') }}
          </p>
        </v-card-title>
        <v-card-title
          v-if="$vuetify.breakpoint.mdAndUp"
          :class="titleClassLeft"
        >
          <v-icon class="mr-2">mdi-fax</v-icon>
          {{ $vuetify.lang.t('$vuetify.fax') }}
        </v-card-title>
        <v-card-title
          v-if="$vuetify.breakpoint.smOnly"
          :class="titleClassLeft"
        >
          <v-icon class="mr-2">mdi-email</v-icon>
          {{ $vuetify.lang.t('$vuetify.email') }}
        </v-card-title>
      </v-col>
      <v-col cols="auto" class="hidden-sm-and-down">
        <v-card-title :class="titleClassRight">
          <v-icon class="mr-2">mdi-map-marker</v-icon>
          {{ $vuetify.lang.t('$vuetify.address') }}
        </v-card-title>
        <v-card-title :class="titleClassRight">
          <v-icon class="mr-2">mdi-email</v-icon>
          {{ $vuetify.lang.t('$vuetify.email') }}
        </v-card-title>
      </v-col>
    </v-row>
    <v-row no-gutters class="hidden-xl-only">
      <v-col cols="auto">
        <p class="mx-2 mt-2 mb-1 caption">
          {{ $vuetify.lang.t('$vuetify.time') }}
        </p>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  computed: {
    titleClassLeft(){
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
          return "text-h6 py-1 pl-2 pr-1"
        default:
          return "text-subtitle-2 py-1 pl-2 pr-1"
      }
    },
    titleClassRight(){
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
          return "text-h6 py-1 pl-1 pr-2"
        default:
          return "text-subtitle-2 py-1 pl-1 pr-2"
      }
    },
    timeClass(){
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
          return "my-2 ml-4"
        default:
          return "my-1 ml-3"
      }
    }
  }
}
</script>
