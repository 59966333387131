<template>
  <div class="team-view">
    <v-row justify="center">
      <v-col cols="12">
        <p class="text-h5 secondary--text">
          Medical Operations
        </p>
      </v-col>
      <v-col cols="auto" v-for="(person, i) in medicalOperations" :key="i">
        <Person
          :name="person.name"
          :profession="person.profession"
          :img="person.img"
        />
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <p class="text-h5 secondary--text">
          IT Department
        </p>
      </v-col>
      <v-col cols="auto" v-for="(person, i) in itDepartment" :key="i">
        <Person
          :name="person.name"
          :profession="person.profession"
          :img="person.img"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Person from "@/views/about/Person"

export default {
  components: {
    Person,
  },
  data(){
    try {
      return {
        medicalOperations: [
          {
            name: "Victoria Weißflog",
            img: require('@/assets/team/Weissflog.jpg'),
            profession: "5",
          },
          {
            name: "Franziska Bachmann",
            img: require('@/assets/team/Bachmann.jpg'),
            profession: "6",
          },
          {
            name: "Andrea Haben",
            profession: "7",
            img: require('@/assets/team/Haben.jpg')
          },
          {
            name: "Antonia Wille",
            profession: "7",
            img: require('@/assets/team/Wille.jpg')
          },
          {
            name: "Mandy Reinbothe",
            img: require('@/assets/team/Reinbothe.jpg'),
            profession: "7",
          },
          {
            name: "Nicole de Lange",
            profession: "7",
            img: require('@/assets/team/Lange.jpg')
          },
          {
            name: "Paulina Stonoga",
            profession: "7",
            img: require('@/assets/team/Stonoga.jpg')
          },  
          {
            name: "Silke Sauer",
            profession: "7",
            img: require('@/assets/team/Sauer.jpg')
          },
          {
            name: "Felix Goßlau",
            profession: "4",
            img:  require('@/assets/team/Gosslau.png')
          },
          {
            name: "Rabea Skubida",
            profession: "3",
            img: require('@/assets/team/Skubida.jpg')
          },
        ],
        itDepartment: [
          {
            name: "Eugen Kinder",
            profession: "1",
            img: require('@/assets/team/Kinder.png')
          },
          {
            name: "Sascha Rudolphi",
            profession: "2",
            img: require('@/assets/team/Rudolphi.jpg')
          },
          {
            name: "Hannah Pütz",
            profession: "3",
            img: require('@/assets/team/Puetz.jpg')
          },
        ],
      }
    } catch (e) {
      return {
        medicalOperations: [],
        itDepartment: []
      }
    }
  }
}
</script>

<style lang="scss">
.team-view {
  text-align: center;
}
</style>
